.contact-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		.img-responsive {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}